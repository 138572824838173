<template>
  <div id="overlay">

    <!--  遮罩层  -->
    <van-popup
        v-model="overShow"
        position="left"
        :duration="0.4"
        closeable
        @close="onClose"
        @click-close-icon="clickCloseIcon"
        :style="{ height: '100%',width:'100%',backgroundColor:'#35234B' }"
    >
      <div class="overIcon"></div>
      <div class="over-txt"
           :key="index"
           v-for="(item,index) in textArr"
           :class="{over_txt_active:isIndex == index}"
           @click="overChange(index)"
      >{{ item.txt }}</div>
      <div class="overlay-footer">
        <img src="./img/overlay-footer.png"  alt="overlay-footer" style="width: 160px;height: 51px">
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from 'vant'

export default {
  name: "overLay",
  components:{
    [Popup.name] : Popup,
  },
  props:['isShow'],
  data(){
    return{
      overShow:true,//遮罩层的状态
      textArr:[
        {txt:"首页",index:1},
        {txt:"关于我们",index:2}
      ],
      isIndex:0,//默认首页字体
      changShow:false,//传给父组件改变遮罩层显示的值
    }
  },
  created() {
    this.isIndex = localStorage.getItem('colorIndex') || 0
  },
  mounted() {
  },
  methods:{

    //蒙层关闭按钮触发事件
    onClose(){
      this.$emit('handleOverShow')
    },
    //点击遮罩层文字变颜色
    overChange(index){
      localStorage.setItem('colorIndex',index)
      this.isIndex = index
      if( index == 0){
        this.$router.push({path:'/home'})
      }else if( index == 1){
        this.$router.push({path:'/about'})
      }
      this.$emit('handleOverShow')
    },





    //遮罩层关闭图标触发事件
    clickCloseIcon(){
      // this.$emit('changeShow',this.overLay)
      // if( e === 0){
      //   this.$router.push({path:"/home"})
      // }else if( e === 1){
      //   this.isIndex = 1
      //   this.$router.push({path:"/about"})
      // }
    },
  },

}
</script>

<style scoped lang="less">
@import "./overLay.less";
/deep/.van-popup__close-icon{
	color: #FFFFFF;
}
</style>